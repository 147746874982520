export const GET_NOTIFICATION_CENTERS: "NOTIFICATION_CENTER/GET_NOTIFICATION_CENTERS" = "NOTIFICATION_CENTER/GET_NOTIFICATION_CENTERS";
export const GET_NOTIFICATION_CENTERS_SUCCESS: "NOTIFICATION_CENTER/GET_NOTIFICATION_CENTERS_SUCCESS" = "NOTIFICATION_CENTER/GET_NOTIFICATION_CENTERS_SUCCESS";
export const GET_NOTIFICATION_CENTERS_FAIL: "NOTIFICATION_CENTER/GET_NOTIFICATION_CENTERS_FAIL" = "NOTIFICATION_CENTER/GET_NOTIFICATION_CENTERS_FAIL";

export const CREATE_NOTIFICATION_CENTER: 'NOTIFICATION_CENTER/CREATE' = 'NOTIFICATION_CENTER/CREATE';
export const CREATE_NOTIFICATION_CENTER_SUCCESS: 'NOTIFICATION_CENTER/CREATE_SUCCESS' = 'NOTIFICATION_CENTER/CREATE_SUCCESS';
export const CREATE_NOTIFICATION_CENTER_FAIL: 'NOTIFICATION_CENTER/CREATE_FAIL' = 'NOTIFICATION_CENTER/CREATE_FAIL';

export const UPDATE_NOTIFICATION_CENTER: "NOTIFICATION_CENTER/UPDATE" = "NOTIFICATION_CENTER/UPDATE";
export const UPDATE_NOTIFICATION_CENTER_SUCCESS: "NOTIFICATION_CENTER/UPDATE_SUCCESS" = "NOTIFICATION_CENTER/UPDATE_SUCCESS";
export const UPDATE_NOTIFICATION_CENTER_FAIL: "NOTIFICATION_CENTER/UPDATE_FAIL" = "NOTIFICATION_CENTER/UPDATE_FAIL";

export const DELETE_NOTIFICATION_CENTER: "NOTIFICATION_CENTER/DELETE" = "NOTIFICATION_CENTER/DELETE";
export const DELETE_NOTIFICATION_CENTER_SUCCESS: "NOTIFICATION_CENTER/DELETE_SUCCESS" = "NOTIFICATION_CENTER/DELETE_SUCCESS";
export const DELETE_NOTIFICATION_CENTER_FAIL: "NOTIFICATION_CENTER/DELETE_FAIL" = "NOTIFICATION_CENTER/DELETE_FAIL";

export const MODAL_OPEN: "NOTIFICATION_CENTER/MODAL_OPEN" = "NOTIFICATION_CENTER/MODAL_OPEN";
export const MODAL_CLOSE: "NOTIFICATION_CENTER/MODAL_CLOSE" = "NOTIFICATION_CENTER/MODAL_CLOSE";

export const PUSH_NOTIFICATION_MODAL_OPEN: "NOTIFICATION_CENTER/PUSH_NOTIFICATION_MODAL_OPEN" = "NOTIFICATION_CENTER/PUSH_NOTIFICATION_MODAL_OPEN";
export const PUSH_NOTIFICATION_MODAL_CLOSE: "NOTIFICATION_CENTER/PUSH_NOTIFICATION_MODAL_CLOSE" = "NOTIFICATION_CENTER/PUSH_NOTIFICATION_MODAL_CLOSE";

export const CLOSE_NOTIFICATION_CENTERS_NOTIF: "NOTIFICATION_CENTER/CLOSE_NOTIF" = "NOTIFICATION_CENTER/CLOSE_NOTIF";
export const CLOSE_NOTIFICATION_CENTERS_ERROR: "NOTIFICATION_CENTER/CLOSE_NOTIFICATION_CENTERS_ERROR" = "NOTIFICATION_CENTER/CLOSE_NOTIFICATION_CENTERS_ERROR";

export const NOTIFY: "NOTIFICATION_CENTER/NOTIFY" = "NOTIFICATION_CENTER/NOTIFY";
export const NOTIFY_SUCCESS: "NOTIFICATION_CENTER/NOTIFY_SUCCESS" = "NOTIFICATION_CENTER/NOTIFY_SUCCESS";
export const NOTIFY_FAIL: "NOTIFICATION_CENTER/NOTIFY_FAIL" = "NOTIFICATION_CENTER/NOTIFY_FAIL";

export const FORM_INPUT_WEBSITE: "website" = "website";
export const FORM_INPUT_EMAIL: "email" = "email";
export const FORM_INPUT_GCM_API_KEY: "gcmApiKey" = "gcmApiKey";
export const FORM_INPUT_CONTENT: "content" = "content";
export const FORM_INPUT_TITLE: "title" = "title";
export const FORM_INPUT_ACTION: "action" = "action";
