
export const AUTHENTICATE: "USER/AUTHENTICATE" = "USER/AUTHENTICATE";
export const AUTHENTICATE_SUCCESS: "USER/AUTHENTICATE_SUCCESS" = "USER/AUTHENTICATE_SUCCESS";
export const AUTHENTICATE_FAIL: "USER/AUTHENTICATE_FAIL" = "USER/AUTHENTICATE_FAIL";

export const GET_PROFILE: "USER/GET_PROFILE" = "USER/GET_PROFILE";
export const GET_PROFILE_SUCCESS: "USER/GET_PROFILE_SUCCESS" = "USER/GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL: "USER/GET_PROFILE_FAIL" = "USER/GET_PROFILE_FAIL";

export const CREATE_USER: 'USER/CREATE' = 'USER/CREATE';
export const CREATE_USER_SUCCESS: 'USER/CREATE_SUCCESS' = 'USER/CREATE_SUCCESS';
export const CREATE_USER_FAIL: 'USER/CREATE_FAIL' = 'USER/CREATE_FAIL';

export const UPDATE_USER: "USER/UPDATE" = "USER/UPDATE";
export const UPDATE_USER_SUCCESS: "USER/UPDATE_SUCCESS" = "USER/UPDATE_SUCCESS";
export const UPDATE_USER_FAIL: "USER/UPDATE_FAIL" = "USER/UPDATE_FAIL";

export const DELETE_USER: "USER/DELETE" = "USER/DELETE";
export const DELETE_USER_SUCCESS: "USER/DELETE_SUCCESS" = "USER/DELETE_SUCCESS";
export const DELETE_USER_FAIL: "USER/DELETE_FAIL" = "USER/DELETE_FAIL";

export const DISCONNECT: "USER/DISCONNECT" = "USER/DISCONNECT";

export const CLOSE_USER_CREATE_NOTIF: "USER/CLOSE_USER_CREATE_NOTIF" = "USER/CLOSE_USER_CREATE_NOTIF";
export const CLOSE_USER_UPDATE_NOTIF: "USER/CLOSE_USER_UPDATE_NOTIF" = "USER/CLOSE_USER_UPDATE_NOTIF";
export const CLOSE_USER_DELETE_NOTIF: "USER/CLOSE_USER_DELETE_NOTIF" = "USER/CLOSE_USER_DELETE_NOTIF";
export const CLOSE_USER_ERROR_NOTIF: "USER/CLOSE_USER_ERROR_NOTIF" = "USER/CLOSE_USER_ERROR_NOTIF";
export const CLOSE_USER_FORGOTTEN_PASSWORD_NOTIF: "USER/CLOSE_USER_FORGOTTEN_PASSWORD_NOTIF" = "USER/CLOSE_USER_FORGOTTEN_PASSWORD_NOTIF";

export const RECOVER_PASSWORD: "USER/RECOVER_PASSWORD" = "USER/RECOVER_PASSWORD";
export const RECOVER_PASSWORD_SUCCESS: "USER/RECOVER_PASSWORD_SUCCESS" = "USER/RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_FAIL: "USER/RECOVER_PASSWORD_FAIL" = "USER/RECOVER_PASSWORD_FAIL";
